<template>
  <div>
    <div style="margin: 20px 0 -20px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="feedback-modify">
      <div class="feedback-modify-main">
        <div class="left">
<!--          <Spin fix v-if="infoLoading"></Spin>-->
          <div >
<!--            <div class="left-top" >-->
<!--              <div v-if="dataList.length">-->
<!--                <div class="correction">-->
<!--                  <div class="correction-type">-->
<!--                    {{$t('trainingcamp_train_second')}}{{curIndex + 1}}{{$t('trainingcamp_train_topic')}}-->
<!--                    <strong>{{ $t('trainingcamp_train_correction_type') }}:</strong>-->
<!--                    <RadioGroup v-model="questionType" size="large" style="margin-left: 20px">-->
<!--                      <Radio v-for="item in typesList" :label="item.name" :key="item.value"></Radio>-->
<!--                    </RadioGroup>-->
<!--                    &lt;!&ndash;                  {{ correctionInfo && typesObj[correctionInfo.type]}}&ndash;&gt;-->
<!--                  </div>-->
<!--                  <div class="correction-tit">-->
<!--                    <div class="correction-left">-->
<!--                      <p class="correction-data-tit">{{ $t('trainingcamp_train_correction_title') }}</p>-->
<!--                    </div>-->
<!--                    <div class="correction-right">-->
<!--                      <Richtxt :height="150" :dataObj="{val:correctionInfo && correctionInfo.name}" @saveData="(data)=>saveCurData(data,correctionInfo,'name')" key="feedback"></Richtxt>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="correction-option-item" v-for="(item,index) in correctionInfo.options" :key="item.id">-->
<!--                    <div class="correction-left">-->
<!--                      <p class="correction-data-tit">{{optionNameList[index]}}</p>-->
<!--                      <p class="correction-correct" :class="item.is_correct == '1' ? 'active' : ''" @click="changeCorrect(item)">{{item.is_correct == '1' ? $t('trainingcamp_train_correction_correct') : $t('trainingcamp_train_correction_set_correct')}}</p>-->
<!--                      <p class="correction-dele" v-if="correctionInfo.options.length > 1" @click="deleOption(index)">{{ $t('trainingcamp_train_correction_delete') }}</p>-->
<!--                    </div>-->
<!--                    <div class="correction-right">-->
<!--                      <Richtxt :dataObj="{val:item.name}" :height="150" @saveData="(data)=>saveCurData(data,item,'name')"></Richtxt>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <Button type="primary" @click="addOption">{{ $t('trainingcamp_train_correction_add_option') }}</Button>-->
<!--                  <div class="correction-analysis">-->
<!--                    <div class="correction-left">-->
<!--                      <p class="correction-data-tit">{{ $t('trainingcamp_train_correction_analysis') }}</p>-->
<!--                    </div>-->
<!--                    <div class="correction-right">-->
<!--                      <Richtxt @saveData="(data)=>saveCurData(data,correctionInfo,'explain')" :height="150" :dataObj="{val:correctionInfo && correctionInfo.explain}" ></Richtxt>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="bot">-->
<!--                  <div class="btn">-->
<!--                    <Button type="error" @click="dele">{{ $t('trainingcamp_exam_feedback_dele') }}</Button>-->
<!--                    <Button type="primary" @click="submit">{{ $t('trainingcamp_exam_feedback_save_submit') }}</Button>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="bot">-->
<!--                  <div class="btn">-->
<!--                    <Button @click="pre">{{$t('trainingcamp_train_pre')}}</Button>-->
<!--                    <Button @click="next">{{$t('trainingcamp_train_next')}}</Button>-->
<!--                    <Button type="error" @click="ignore">{{ $t('trainingcamp_exam_feedback_ignore') }}</Button>-->
<!--                    <Button type="primary" @click="save()">{{$t('trainingcamp_exam_feedback_save')}}</Button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div v-else style="text-align: center">{{ $t('trainingcamp_exam_feedback_no_data') }}</div>-->
<!--            </div>-->
            <div class="left-right">
              <div v-if="dataList.length">
                <div style="display: flex;justify-content: space-between; align-items: center">
                  <p class="tit">{{ $t('trainingcamp_exam_feedback_feedback') }} -- {{dataList[this.curIndex].user.nickname + '('+dataList[this.curIndex].user_id+')'}}</p>
                  <p>{{dataList[this.curIndex].created_at}}</p>
                </div>

                <div class="feedback-item">
                  <div class="feedback-item-tit">
                    {{ $t('trainingcamp_exam_feedback_feedback_tit') }}
                  </div>
                  <div class="feedback-item-cont">
                    <Input v-model="dataList[this.curIndex].title" disabled :placeholder="$t('trainingcamp_exam_feedback_feedback_tit')"  />
                  </div>
                </div>
                <div class="feedback-item">
                  <div class="feedback-item-tit">
                    {{ $t('trainingcamp_exam_feedback_feedback_cont') }}
                  </div>
                  <div class="feedback-item-cont">
                    <Richtxt :height="150" :isDisabled="true" :dataObj="{val:dataList[this.curIndex].content}"></Richtxt>
                  </div>
                </div>
                <div class="feedback-item">
                  <div class="feedback-item-tit">
                    反馈图片
                  </div>
                  <div class="feedback-item-cont">
                    <img :src="dataList[this.curIndex].img" width="30%" />
                  </div>
                </div>
              </div>
              <div v-else style="text-align: center">{{ $t('trainingcamp_exam_feedback_no_data') }}</div>
            </div>
          </div>


        </div>

        <div class="right">
          <div class="tab">
            <div class="tab-list">
              <div :class="status == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">
                {{ item.name }}
              </div>
            </div>
            <div class="count">
              {{ $t('trainingcamp_exam_feedback_surplus') }}<span>{{ remainingTotal }}</span>{{ $t('trainingcamp_exam_feedback_topic') }}
            </div>
          </div>
          <div class="search">
            <Input v-model="keyword"
                   :placeholder="$t('trainingcamp_exam_feedback_search')"
                   clearable
                   search
                   :enter-button="true"
                   @on-enter="getList"
                   @on-search="getList"
            />
          </div>
          <div class="right-top">
            <div class="tit">
              <div>
                {{ $t('trainingcamp_train_answer_sheet') }}
                <Tooltip>
                  <Icon type="ios-help-circle-outline" />
                  <div slot="content">
                    <p>{{ $t('trainingcamp_train_white') }}</p>
                    <p>{{ $t('trainingcamp_train_blue') }}</p>
                    <p>{{ $t('trainingcamp_train_green') }}</p>
                    <p>{{ $t('trainingcamp_train_red') }}</p>
                  </div>
                </Tooltip>
              </div>
              <Button type="text" @click="refresh">{{ $t('trainingcamp_exam_feedback_refresh') }}</Button>
            </div>

            <div class="answer-card">
              <Spin fix v-if="loading"></Spin>
              <div v-else>
                <div class="list" v-if="dataList.length">
                  <div :class="{active:dataList[curIndex].id == item.id,error:!item.status == '2',corret:item.status == '1'} " v-for="(item,index) in dataList" :key="item.id" @click="changeAnswerCard(index)">{{index + 1}}</div>
                </div>
                <div v-else style="text-align: center">
                  {{ $t('trainingcamp_exam_feedback_no_data') }}
                </div>
                <div class="page">
                  <Page :total="remainingTotal" :current="page" :page-size="pageSize" @on-change="changePage" />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleModal :status="deleModalStatus" :txt="$t('dataset_data_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
    </div>
  </div>

</template>

<script>
import DeleModal from '@/components/deleteModal.vue';
import Richtxt from '@/components/production/richtxt.vue';
import util from '@/utils/tools.js';

export default {
  name: "role",
  data(){
    return{
      page:1,
      pageSize:50,
      status:0,  //0待复核1通过2未通过的
      dataList:[],
      loading:true,
      allowLoading:false,
      notAllowLoading:false,
      typesObj:{},
      typesList:[],
      curIndex:0,
      optionList:['A','B','C','D','E','F'],
      correctionModal:false,
      optionNameList:[this.$t('trainingcamp_train_correction_option_a'),this.$t('trainingcamp_train_correction_option_b'),this.$t('trainingcamp_train_correction_option_c'),this.$t('trainingcamp_train_correction_option_d'),this.$t('trainingcamp_train_correction_option_e'),this.$t('trainingcamp_train_correction_option_f'),this.$t('trainingcamp_train_correction_option_g')],
      correctionInfo:{},
      feedbackModal:false,
      formItem:{
        title:'',
        feedback:'',
      },
      ruleValidate: {
        title:[{ required: true, message: this.$t('trainingcamp_train_feedback_tit_placeholder'), trigger: 'blur' }],
        feedback: [
          { required: true, message: this.$t('trainingcamp_train_feedback_cont_placeholder'), trigger: 'blur' }
        ]
      },
      radio:'',
      checkbox:[],
      correctIndex:'',
      correctAnswer:'',
      keyword:'',
      tabList:[],
      remainingTotal:0,
      infoLoading:false,
      deleModalStatus:false,
      modalLoading:false,
      total:0,
      questionType:'',
    }
  },
  components:{
    Richtxt,
    DeleModal
  },
  created(){
    this.getList();
  },
  methods:{
    refresh(){
      this.curIndex = 0;
      this.getList();
    },
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        status:this.status, //0待处理1已处理2忽略
        keyword:this.keyword,
      };
      this.api.dataset.feedbackList(params).then((res)=>{
        this.dataList = res.list;
        this.dataList.forEach((item)=>{
          item.created_at = util.timeFormatter(
              new Date(+item.created_at * 1000), 'yyyy-MM-dd hh:mm'
          );
        })
        this.curIndex = 0;
        this.correctAnswer = '';
        // if(this.dataList.length){
        //   this.formateInfo();
        // }
        this.loading = false;
        this.remainingTotal = Number(res.count);
        this.tabList = [];
        this.typesObj = res.data_types;

        this.typesList = [];
        for(let name in res.data_types){
          this.typesList.push({
            name:res.data_types[name],
            value:name
          })
        }
        for(let name in res.statuses){
          this.tabList.push({
            name:res.statuses[name],
            id:name
          })
        }
        // this.getInfo();

      }).catch((err)=>{
        this.loading = false;
      })
    },
    getInfo(){
      let params = {
        id:this.dataList[this.curIndex].data_id
      };
      this.infoLoading = true;
      this.api.dataset.dataDetail(params).then((res)=>{
        this.infoLoading = false;
        this.correctionInfo = JSON.parse(JSON.stringify(res.info));

        this.questionType = this.typesObj[this.correctionInfo.type];
      }).catch((err)=>{
        this.infoLoading = false;
      });
    },
    changeAnswerCard(index){
      this.curIndex = index;
      // this.getInfo();
      // this.formateInfo();
    },
    next(){ //下一题
      if(this.curIndex == this.dataList.length - 1){
        this.$Message.warning(this.$t('trainingcamp_train_last_test'));
        return;
      }
      this.curIndex = this.curIndex + 1;
      // this.getInfo();
      // this.formateInfo();
    },
    pre(){  //上一题
      if(this.curIndex == 0){
        this.$Message.warning(this.$t('trainingcamp_train_first_test'));
        return;
      }
      this.curIndex = this.curIndex - 1;
      // this.getInfo();
      // this.formateInfo();
    },
    save(fn){
      if(!this.correctionInfo.name){  //标题
        this.$Message.warning(this.$t('trainingcamp_train_warning_tit'));
        return;
      }
      if(!this.correctionInfo.options.length){ //选项
        this.$Message.warning(this.$t('trainingcamp_train_warning_add_option'));
        return;
      }
      let len = this.correctionInfo.options.filter((item)=>{
        return item.is_correct == '1';
      }).length
      if(!len){ //正确选项
        this.$Message.warning(this.$t('trainingcamp_train_warning_select'));
        return;
      }
      for(let i=0;i<this.correctionInfo.options.length;i++){
        if(!this.correctionInfo.options[i].name){
          this.$Message.warning(this.$t('trainingcamp_train_warning_input_cont'));
          return;
          break;
        }
      }
      // if(!this.correctionInfo.datasetData.explain){ //解析
      //   this.$Message.warning('请输入解析');
      //   return;
      // }
      let type = this.typesList.filter((item)=>{
        return item.name == this.questionType
      })[0].value;

      let params = {
        id:this.correctionInfo.id,
        name:this.correctionInfo.name,
        explain:this.correctionInfo.explain,
        options:JSON.stringify(this.correctionInfo.options),
        type:type,
        level:this.correctionInfo.level
      };

      this.api.dataset.dataUpdate(params).then((res)=>{
        this.$Message.success(this.$t('trainingcamp_exam_feedback_save_success'));
        if(fn){
          fn();
        }
        this.curIndex++;
        // this.getInfo();
      })
    },
    submit(){ //保存并提交
      this.save(()=>{
        this.$set(this.dataList[this.curIndex],'status','1');
        this.feedbackUpdate(1)
      });
    },
    ignore(){ //忽略
      this.feedbackUpdate(2,()=>{
        this.$set(this.dataList[this.curIndex],'status','2');
        this.$Message.success(this.$t('trainingcamp_exam_feedback_save_success'));
        this.curIndex++;
        // this.getInfo();
      })
    },
    feedbackUpdate(status,fn){
      let params = {
        id:this.dataList[this.curIndex].id,
        status:status
      };
      this.api.dataset.feedbackUpdate(params).then((res)=>{

        fn && fn();
      })
    },
    saveCurData(curData,data,name){
      console.log('eeeeeee',curData)
      data[name] = curData;
    },
    changeCorrect(data){  //修改正确选项
      if(this.correctionInfo.type == '1' || this.correctionInfo.type == '3'){ //单选
        if(data.is_correct == '0'){
          this.correctionInfo.options.forEach((item)=>{
            item.is_correct = '0';
          });
          this.$nextTick(()=>{
            data.is_correct = '1';
          })

          console.log(data)
        }else{
          this.$nextTick(()=>{
            data.is_correct = '0';
          })
        }
      }else{
        data.is_correct == '0'  ? data.is_correct = '1' : data.is_correct = '0';
      }

    },
    addOption(){  //添加选项
      if(this.correctionInfo.options.length == 6){
        this.$Message.warning(this.$t('trainingcamp_train_option_max'));
        return;
      }
      this.correctionInfo.options.push({
        name:'',
        id:'0',
        is_correct:'0'
      })
    },
    deleOption(index){  //删除选项
      this.correctionInfo.options.splice(index, 1);
    },
    confirmCorrection(){  //确认纠错
      if(!this.correctionInfo.name){  //标题
        this.$Message.warning(this.$t('trainingcamp_train_warning_tit'));
        return;
      }
      if(!this.correctionInfo.options.length){ //选项
        this.$Message.warning(this.$t('trainingcamp_train_warning_add_option'));
        return;
      }
      let len = this.correctionInfo.options.filter((item)=>{
        return item.is_correct == '1';
      }).length
      if(!len){ //正确选项
        this.$Message.warning(this.$t('trainingcamp_train_warning_select'));
        return;
      }
      for(let i=0;i<this.correctionInfo.options.length;i++){
        if(!this.correctionInfo.options[i].name){
          this.$Message.warning(this.$t('trainingcamp_train_warning_input_cont'));
          return;
          break;
        }
      }
      // if(!this.correctionInfo.datasetData.explain){ //解析
      //   this.$Message.warning('请输入解析');
      //   return;
      // }

      let params = {
        data_id:this.correctionInfo.id,
        name:this.correctionInfo.name,
        explain:this.correctionInfo.explain,
        options:JSON.stringify(this.correctionInfo.options),
        type:this.correctionInfo.type,
        level:this.correctionInfo.level
      };

      this.api.dataset.correctionCreate(params).then((res)=>{
        this.correctionModal = false;
        this.$Message.success(this.$t('trainingcamp_train_correction_success'));
        // this.getInfo()
      })
    },
    changeTab(data){
      this.radio = '';
      this.checkbox = [];
      this.correctIndex = '';
      this.correctAnswer = '';
      this.curIndex = 0;
      this.status = data.id;
      this.getList();
    },
    dele(){
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        id:this.correctionInfo.id
      }
      this.modalLoading = true;
      this.api.dataset.dataDelete(data).then((res)=>{
        this.$set(this.dataList[this.curIndex],'status','1');
        this.feedbackUpdate(1)
        this.curIndex++;
        // this.getInfo();
        this.modalLoading = false;
        this.deleModalStatus = false;

      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    changePage(page){
      this.page = page;
      this.getList();
    }
  }
}
</script>

<style scoped lang="scss">
.feedback-modify{
  padding: 20px;
  font-size: 14px;
  .feedback-modify-main{
    display: flex;
    justify-content: space-between;
  }

  .left{
    flex: 1;
    margin-right: 20px;
    position: relative;

    >div{
      display: flex;
      justify-content: flex-start;
    }

    .left-top{
      flex: 3;
      margin-right: 20px;
      padding:20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      font-size: 16px;
      position: relative;

      .tit{

        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        >span:nth-child(2){
          margin-left: 20px;
          font-size: 16px;
          font-weight: normal;
        }
        >span:nth-child(3){
          margin-left: 5px;
          font-size: 12px;
          font-weight: normal;
        }
      }
      .name{
        margin:10px 0 ;
      }
      .cont{
        .option{
          margin-bottom: 10px;
        }
      }
      .answer-cont{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        >span:nth-child(1){
          width: 50px;
        }
        >span:nth-child(2){
          flex: 1;
          width: 0;
          word-break: break-all;
          word-wrap: break-word;
          white-space: pre-wrap;
        }
      }
      .bot{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn{
          button{
            margin-right: 10px;
          }
        }

        .op{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          >div{
            margin-left: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            >img{
              margin-right: 5px;
              width: 20px;
              height: 20px;
            }
          }

        }
      }
    }
    .answer{
      margin-top: 40px;
      margin-bottom: 10px;

      .blue{
        color: #2d8cf0;
      }
      .green{
        color: #19be6b;
      }
      .red{
        color: #ed4014;
      }
    }
    .left-right{
      flex: 2;

      padding:20px;
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;
      font-size: 16px;
      position: relative;
      height: 644px;
    }
  }
  .right{
    width: 400px;
    .tab{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      line-height: 40px;
      font-size: 16px;

      .tab-list{
        border: 1px solid #dcdee2;
        border-radius: 4px;
        overflow: hidden;
        height: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #FFFFFF;
        >div{
          height: 100%;
          padding: 0 20px;
          border-right: 1px solid #dcdee2;
        }
        >div:last-child{
          border-right: none;
        }
        >div.active{
          background-color: #2d8cf0;
          color: #FFFFFF;
        }
      }
      .count{
        font-size: 14px;
        >span{
          padding: 0 5px;
          color:#2d8cf0;
        }
      }
    }
    .search{
      margin-bottom: 20px;
    }
    .catrgory-search{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .right-top{
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;


      .tit{
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #dcdee2;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .again{
        white-space:normal
      }
      .answer-card{
        margin: 20px 10px;
        height: 450px;
        overflow-y: auto;
        position: relative;
        .list{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          >div{
            width: 50px;
            height: 30px;
            border: 1px solid #dcdee2;
            text-align: center;
            line-height: 30px;
            border-radius: 4px;
            margin: 0 10px 10px 10px;
            cursor: pointer;
          }

          >div.error{
            background-color: #ec808d;
            color:#FFFFFF;
          }
          >div.corret{
            background-color: #19be6b;
            color:#FFFFFF;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            //border: 1px solid #2d8cf0;
          }
          >div.error.active{
            background-color:red;
            border: none;
          }
          >div.corret.active{
            background-color: green;
            border: none;
          }
        }
        .page{
          margin-top: 15px;
          text-align: right;
        }
      }
      .num{
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        span{
          padding-left:5px;
          padding-right: 2px;
          font-weight: bold;
        }

        .blue{
          color:#19be6b;
        }
        .red{
          color:#ed4014;
        }
      }
    }
  }
  .option{
    font-size: 16px;
    //white-space: -moz-pre-wrap;
    white-space:normal;
    height: auto;
  }
  .checkoutOption{
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    white-space:normal;
    height: auto;
  }
}
.bjColor{
  background-color: #dee6de;
}
.nrBjColor{
  background-color: #f0faef !important;
}
.big-font-size{
  font-size: 18px !important;
}
.little-font-size{
  font-size: 14px!important;
}
.middle-font-size{
  font-size: 16px!important;
}
.correction,
.left-right{  //纠错
  //height: 500px;
  overflow-y: auto;
  .correction-type,
  .tit{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .correction-tit,
  .correction-option-item,
  .correction-analysis,
  .feedback-item{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    .correction-left,
    .feedback-item-tit{
      width: 100px;
      text-align: right;
      font-size: 14px;
      overflow: hidden;


      .correction-correct{
        margin: 5px 0;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        cursor: pointer;
      }
      .correction-dele{
        float: right;
        width: 50px;
        color:#FFFFFF;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        background-color: #ed4014;
        cursor: pointer;
      }
      .active{
        border: 1px solid #19be6b;
        background-color: #19be6b;
        color:#FFFFFF;
      }
    }
    .correction-right,
    .feedback-item-cont{
      margin-left: 20px;
      flex: 1;
      //border: 1px solid #dcdee2;
    }
  }
  .feedback-item{
    display: block;
  }
  .feedback-item-tit{
    text-align: left !important;
    margin-bottom: 10px;
  }
  .feedback-item-cont{
    margin-left: 0!important;
  }
  .correction-analysis{
    margin-top: 20px;
  }
}
</style>
<style>
.data-verify .ivu-checkbox-disabled+span{
  color:#515a6e !important;
}
</style>
